@import '../../../sass/variables.module';
@import '../../../sass/mixins';

.navigatie-grid-container {
  width: 100%;
  @include big-grid-columns;
  position: relative;

  .navigatie-container {
    display: flex;
    grid-column: center-start / center-end;
    align-items: center;

    @include respond(tab-land) {
      margin-left: 5rem;
      margin-right: 5rem;
    }

    & a {
      text-decoration: none;
    }

    .navigatie {
      width: 100%;
      display: flex;
      justify-content: center;

      &-item {
        text-decoration: none;
        font-size: 1.8rem;
        font-weight: 800;
        text-transform: uppercase;
        color: $color-primary;
        cursor: pointer;
        position: relative;
        margin: 0 2rem;
      }

      &-item::before {
        content: '';
        position: absolute;
        bottom: -.5rem;
        left: 0;
        height: .3rem;
        width: 0;
        background-color: $color-primary;
        transition: transform 0.2s, width cubic-bezier(1, 0, 0, 1) 0.2s,
          background-color 0.1s;
      }

      &-item:hover:before,
      &-item--selected::before {
        width: 100%;
      }

      // &-item:not(:nth-child(2)):not(:nth-child(5)) {
      //   margin-right: 4rem;
      // }

      // .octopus-arm-navbar {
      //   height: 6rem;
      //   width: auto;
      //   margin: -3rem 4rem 0 4rem;
      // }
    }
  }
}

.header-btn {
  // align-self: flex-start;
}

.header-logo {
  // transform: translateY(-20%);
}
