@import '../../../sass/variables.module';
@import '../../../sass/mixins';

.card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background-color: $color-white;
    border-radius: .8rem;
    height: 29rem;
    width: 21rem;
    box-shadow: $box-shadow-primary;
    
    @include respond(phone) {
        margin-bottom: 4rem;
    }

    &-keywords {
        color: $color-grey; 

        &-p {
            @include respond(tab-port) {
                font-size: 14px;
            }
        }
    }

    &-heading {
        margin-top: 2rem;

        &-p {
            @include respond(tab-port) {
                font-size: 15px;
            }
        }
    }
}