@import '../../../sass/mixins';

.expertise-keywords-grid {
  display: grid;
  gap: 2rem 9rem;
  grid-template-columns: max-content max-content;
  margin-top: 3rem;

  & > span {
    color: rgba(255, 255, 255, 0.3);
    font-size: 1.65rem;

    @include respond(tab-port) {
      font-size: 1.55rem;
    }
  }

  @include respond(phone) {
    gap: 2rem 4rem;
  }
}