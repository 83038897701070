@import '../../../sass/variables.module';
@import '../../../sass/mixins';

.we-build-items {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;

  &-icon {
    border-radius: 5px;

    svg {
      display: flex;
      align-items: center;
      background-color: $color-secondary-light;
      padding: 1.1rem;
      border-radius: 7px;
      margin-bottom: 1rem;
    }
  }

  &-head {
    font-size: 1.9rem;
  }

  &-text {
    text-align: center;
    max-width: 85%;

    @include respond(phone) {
      max-width: 75%;
    }
  }

  @include respond(phone) {
    margin-bottom: 5rem;
  }
}
