@import "../../../sass/mixins";

.ourWorkSection-container {
  padding: 10rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > h2:nth-of-type(1) {
    justify-content: center;
  }
}