@import '../../../sass/variables.module';
@import '../../../sass/mixins';

$workflow-stepper-color: #E8E8E8;

.stepper-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    transform: translateX(2rem);

    .stepper {
        flex-direction: column;
        border-left: 3px solid $workflow-stepper-color;
        
        &-item:last-child {
            margin-bottom: -.5rem;
        }

        @include respond(tab-port) {
            display: flex;
            flex-basis: calc(50% - 14px);
            justify-content: center;

            &:nth-child(-n + 2) {
                margin-bottom: 4rem;
            }
        }

        @include respond(phone) {
            display: flex;
            flex-basis: calc(100% - 14px);
            justify-content: center;

            &:nth-child(3) {
                margin-bottom: 4rem;
            }
        }
    }
}