@import '../../../sass/variables.module';
@import '../../../sass/mixins';

.whatWeDoSection-container {
    margin-bottom: 8rem;
    text-align: center;

    @include respond(tab-port) {
        margin-top: 6rem;
    }

    @include respond(phone) {
        margin-bottom: 0;
    }
}

.card-container {
    display: flex;
    justify-content: space-evenly;
    margin-top: 8rem;
    perspective: 100rem;

    @include respond(phone) {
        flex-direction: column;
        align-items: center;
        margin-top: 6rem;
    }

    svg {
        @include respond(tab-port) {
            width: 45px;
            height: 35px;
        }
    }
}