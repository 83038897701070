// Some things in CSS are a bit tedious to write, especially with CSS3 and the many vendor prefixes that exist. 
// A mixin lets you make groups of CSS declarations that you want to reuse throughout your site. 
// You can even pass in values to make your mixin more flexible. A good use of a mixin is for vendor prefixes. 
// Here's an example for theme.
// https://sass-lang.com/guide -> scroll to mixins

@import '../sass/variables.module';

// GRID MANAGER

@mixin big-grid-columns {
  display: grid;
  grid-template-columns: [full-start] 1fr [center-start] repeat(8, [col-start] minmax(min-content, 17rem) [col-end]) [center-end] 1fr [full-end];
}

@mixin grid-template-rows {
  display: grid;
  grid-template-rows: 94vh repeat(5, min-content);
}

// MEDIA QUERY MANAGER

/*
0 - 600px:      Phone
600 - 900px:    Tablet portrait (vertical)
900 - 1200px:   Tablet landscape (horizontal)
1200 - 1800px:  Where our normal styles apply
1800px + :      Big desktop

$breakpoint argument choices
- phone
- tab-port
- tab-land
- big-desktop

ORDER: Base + typography > general layout + grid > page layout > components
1rem = 16px
*/

@mixin respond($breakpoint) {
  @if $breakpoint == phone {
      @media only screen and (max-width: 600px) { @content; }    //600px
  }

  @if $breakpoint == tab-port {
      @media only screen and (max-width: 900px) { @content; }   //900px
  }

  @if $breakpoint == tab-land {
      @media only screen and (max-width: 1200px) { @content; }      //1200px
  }

  @if $breakpoint == big-desktop {
      @media only screen and (min-width: 1800px) { @content; }   //1800px
  }
}


