@import '../../sass/variables.module';
@import '../../sass/mixins';

.overons-container {
  @include big-grid-columns;
  grid-template-rows: auto repeat(5, min-content);

  @include respond(tab-port) {
    margin-top: 10rem;
  }
}

.overons-landing {
  grid-column: center-start / center-end;
  display: grid;
  align-items: center;
  height: 70vh;
  margin-top: 5rem;

  @include respond(tab-land) {
    height: min-content;
    margin-top: 2rem;
  }
  
}
.what-we-value {
  grid-column: full-start / full-end;
  display: grid;
  align-items: center;
  background-color: $color-grey-light;
  padding: 8rem 0;
  
}
.our-team {
  grid-column: full-start / full-end;
}
.we-build {
  grid-column: center-start / center-end;
}

.stay-up-to-date {
  grid-column: full-start / full-end;
}
.homepage-footer {
  grid-column: full-start / full-end;
}
