@import '../../../sass/variables.module';
@import '../../../sass/mixins';

.footer {
  &-container {
    @include big-grid-columns;
    display: grid;
  }

  &-top {
    grid-column: center-start / center-end;
    padding: 3rem 0;
  }

  &-content {
    grid-column: center-start / center-end;
    display: flex;

    @include respond(phone) {
      flex-direction: column;
    }

    &-left {
      flex: 3;
      padding-right: 2rem;

      h2 {
        font-size: 4rem !important;
      }

      @include respond(phone) {
        p {
          font-size: 2rem;
          max-width: 80%;
          margin-bottom: 3rem !important;
        }

        h2 {
          font-size: 4.5rem;
        }
      }
    }
    &-right {
      flex: 2;

      @include respond(phone) {
        p {
          font-size: 1.8rem  !important;
          margin-bottom: 1.2rem;
          text-decoration: underline;
        }
      }

      .form {
        display: flex;
        align-items: center;
        border-style: solid;
        border-color: $color-secondary-light;
        background-color: $color-secondary-light;
        text-align: center;
        border-radius: 1rem;
        margin-bottom: 5rem;

        @include respond(phone) {
          margin-bottom: 3rem;
        }

        &-send {
          width: 20%;
          font-size: 3rem;

          &:hover {
            cursor: pointer;
            color: $color-secondary;
          }
        }

        &-input {
          border-style: none;
          border-radius: 1rem;
          color: $color-secondary-light;
          width: 100%;
          background-color: $color-primary;
          padding: 1rem;
          font-family: $default-font-family;
          font-size: 1.7rem;

          &:focus {
            outline: none;
          }
        }
      }

      .link_decoration {
        text-decoration: none;
      }

      .socials {
        &-visit-container {
          display: flex;

          @include respond(phone) {
            flex-direction: column;
          }

          &-left {
            flex: 1;

            @include respond(phone) {
              margin-bottom: 3rem;
            }
          }

          &-right {
            flex: 1;
          }
        }

        &-icon {
          margin-right: 1.5rem;
          font-size: 3rem;
          color: $color-secondary-light;
          stroke-width: 1.5px;

          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }

  &-bottom {
    grid-column: center-start / center-end;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2rem 0;

    &-left {
      justify-self: flex-start;

      @include respond(phone) {
        max-width: 93%;
      }
    }

    &-right {
      justify-self: flex-end;

      @include respond(phone) {
        display: flex;
        flex-direction: column;
      }

      & > .footer-link {
        margin-left: 2rem;
      }
    }

    .footer-link,
    span {
      text-decoration: none;
      color: $color-white;
      opacity: 0.6;
      font-size: 1.5rem;
      font-weight: 200;

      @include respond(phone) {
        font-size: 1.6rem;
      }

      .footer-link:hover {
        cursor: pointer;
      }
    }
  }
}

.footer-heading-tertiary {
  margin-bottom: 1rem;

  @include respond(phone) {
    margin-bottom: 0;
  }
}
