@import '../../../sass/variables.module';
@import '../../../sass/mixins';

.darkoverlay {
  position: fixed;
  height: 100vh;
  width: 100vw;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 98;
}