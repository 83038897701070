@import '../../sass/variables.module';
@import '../../sass/mixins';

$border-radius: .5rem;

.services-container {
  @include big-grid-columns;

  display: grid;
  grid-template-rows: 1fr repeat(5, min-content);
}

.what-we-do {
  background-color: rgba($color-secondary-light, .20);
  grid-column: center-start / center-end;
  padding: 5rem 17rem;
  margin-top: 4rem;
  border-radius: $border-radius;

  width: auto;
  justify-self: center;

  @include respond(tab-land) {
    width: min-content;
  }

  @include respond(phone) {
    width: auto;
    background-color: transparent;
    padding: 2rem 3.8rem;
  }
}

.added-value {
  grid-column: center-start / center-end;
  padding: 8rem 0;
  border-radius: $border-radius;

  @include respond(tab-land) {
    padding: 6rem;
  }
}

.expertise {
  background-color: $color-black;
  grid-column: full-start / full-end;
  padding: 10rem 0;

  @include respond(tab-land) {
    padding: 6rem;
  }
}

.workflow {
  grid-column: center-start / center-end;
  padding: 8rem 0;
  border-radius: $border-radius;

  @include respond(tab-land) {
    padding: 6rem;
  }
}