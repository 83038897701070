@import '../../../../sass/variables.module';

.primary-button {
  text-transform: capitalize;
  border-radius: 5px;
  padding: 1.4rem 2rem;
  transition: 0.3s ease;
  font-family: inherit;
  font-size: 1.6rem;

   &:disabled {
    opacity: 0.6;

    &:hover, &:active {
      pointer-events: none;
      background-color: $color-primary;
    }
  }

  &--full {
    background-color: $color-primary;
    color: $color-white;
    border: none;
    font-weight: 700;

    &:active,
    &:hover {
      background-color: $color-primary-dark;
    }
  }

  &--outlined {
    background-color: transparent;
    color: $color-primary;
    border: .2rem solid $color-primary;
    font-weight: 700;

    &:active,
    &:hover {
      background-color: $color-primary;
      color: $color-white;
    }
  }

  &--uppercase {
    text-transform: uppercase;
  }

  &--bold {
    font-weight: bold;
  }

  &:hover {
    cursor: pointer;
  }
}