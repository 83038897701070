@import '../../../sass/mixins';

.expertise {
  &-container {
    @include big-grid-columns;
    display: grid;
  }
  
  &-content {
    grid-column: center-start / center-end;
    display: grid;
    gap: 6rem 5rem;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    justify-items: flex-start;

    & > svg {
      justify-self: center;

      @include respond(phone) {
        display: none;
      }
    }

    @include respond(tab-port) {
      gap: 6rem 0rem;
    }

    @include respond(phone) {
      grid-template-columns: 1fr;

      p {
        max-width: 95%;
      }
    }
  }

  &-left {
    transform: translateX(-50%);
  }
  
  &-right {
    transform: translateX(50%);
  }

  &-right, &-left {
    @include respond(tab-land) {
      width: 140px;
      height: 140px;
    }
  }
}