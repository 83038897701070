@import '../../../sass/variables.module';
@import '../../../sass/mixins';

$time-line-box-height: 5.5rem;

.homepage-landing-container {
  display: grid;
  grid-template-columns: 2.5fr 2fr 1fr;

  @include respond(tab-port) {
    grid-template-columns: 2fr 1fr;
    margin-bottom: 13rem;
  }

  @include respond(phone) {
    grid-template-columns: 1fr;
    gap: 3rem;
  }
}

.homepage-left {
  grid-column: span 1;

  .homepage-paragraph {
    color: $color-primary-light;
  }

  .get-started-container {
    display: inline-flex;
    align-items: center;
    margin-top: 4rem;

    &:hover {
      .get-started-circle {
        background-position: 100% 0;
        transition: all 0.6s ease-in-out;
      }
    }

    &:focus {
      .get-started-circle {
        outline: none;
      }
    }

    .get-started-circle {
      background-color: $color-secondary;
      background-size: 300% 100%;
      height: 6.5rem;
      width: 6.5rem;
      border-radius: 50%;
      cursor: pointer;

      background-size: 300% 100%;
      transition: all 0.6s ease-in-out;

      background-image: linear-gradient(to left, $color-secondary, $color-secondary, $color-secondary-light, $color-secondary);
      box-shadow: 0 0 2rem rgba(0, 0, 0, 0.3);
    
    }

    .get-started-text {
      color: $color-primary;
      text-transform: uppercase;
      font-size: 1.8rem;
      font-weight: 800;
      position: absolute;
      transform: translateX(15%);
      letter-spacing: 2.5px;
      margin-top: 4px;
      cursor: pointer;
  
    }
  }

  .skill-icons-container {
    margin-top: 4rem;

    & > *:not(:last-child) {
      margin-right: 2rem;
    }
  }
}

.homepage-right {
  grid-column: 2 / 3;
  justify-self: center;

  @include respond (tab-port) {
    display: none; 
  }

  .circle-card {
    background-color: $color-white;
    border-radius: .8rem;
    height: 29rem;
    width: 19rem;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 15rem rgba(0, 0, 0, 0.2);

    &-container {
      position: relative;
    }

    &--2 {
      position: absolute;
      top: 13rem;
      left: 8rem;
    }

    .circle {
      border-radius: 50%;
      height: 7rem;
      width: 7rem;

      &--1 {
        background-color: $color-secondary-light;
      }

      &--2 {
        background-color: $color-primary;
      }
    }
  }
}

.time-line-container {
  grid-column: 3 / 4;
  position: relative;
  height: min-content;
  justify-self: flex-end;

  @include respond (tab-port) {
    grid-column: 2 / -1;
    // justify-self: f;
  }

  @include respond(phone) {
    grid-column: 1;
    justify-self: flex-start;
  }

  .time-line-box-container {
    display: flex;
    align-items: center;
    position: relative;
    
    &:not(:last-child) {
      margin-bottom: 10rem;
    }
    
    .time-line-box {
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;

      background-color: $color-primary;
      border-radius: .5rem;
      height: $time-line-box-height;
      width: $time-line-box-height;
      margin-right: 2rem;
    }

    .time-line-text {
      color: $color-primary;
      font-weight: 800;
      text-transform: uppercase;
      font-size: 1.6rem;
    } 
  }

  &::before {
    content: "";
    position: absolute;
    left: $time-line-box-height / 2;
    transform: translateX(-50%);
    height: 100%;
    width: 3px;
    background-color: $color-secondary-light;
  }
}

.timeline-icon {
  color: $color-white;
}
