@import '../../../sass/mixins';

.carousel {
  width: 100vw;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;

  &-row {
    position: relative;
    height: 100%;
    width: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;

    // &::after {
    //   content: '';
    //   width: 100%;
    //   height: 100%;
    //   position: absolute;
    //   left: 0;
    //   top: 0;
    // }
  }

  &-container {
    position: absolute;
    overflow: hidden;
    border-radius: 30px;
    width: 50vw;
    height: 90%;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);
    
    & > img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  &-btn {
    position: relative;
    height: 6rem;
    width: 6rem;
    border: none;
    background-color: transparent;
    position: absolute;
    transform: translateY(-50%);
    
    &--left { left: 5%; }
    &--right { right: 5%; }

    &-container {
      @include respond(tab-port) {
        display: none;
      }
    }

    & > svg {
      height: 100%;
      width: 100%;
      stroke-width: 1px;
      color: rgb(109, 109, 109);
      transition: color .2s ease-out;

      &:hover {
        cursor: pointer;
        color: #000;
      }
    }
  }
}