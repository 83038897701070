@import '../../../sass/variables.module';
@import '../../../sass/mixins';

.our-story-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;

  @include respond(tab-land) {
    padding-left: 6rem;
    padding-right: 6rem;
  }

  @include respond(tab-port) {
    gap: 0rem;
  }

  @include respond(phone) {
    grid-template-columns: 1fr;
    padding-bottom: 6rem;
  }

  &-image {
    transform: translateY(-3rem);

    @media screen and (max-width: 1425px) {
      width: 40rem;
      transform: translateY(-7rem);
    }

    @include respond(tab-land) {
      width: 30rem;
    }

    @include respond(phone) {
      display: none;
    }
  }

  & div:nth-child(1) {
    @include respond(tab-land) {
      padding-right: 3rem;
    }

    @include respond(phone) {
      padding-right: 0;
    }
  }

  & div:nth-child(2) {
    justify-content: right;
    display: grid;

    @include respond(tab-land) {
      align-items: center;
    }
  }

  &-arrow {
    cursor: pointer;
    color: $color-secondary;
    margin-top: 10px;
    transition: all 700ms;

    &:hover {
      color: $color-primary;
      margin-top: 10px;
    }

    @include respond(tab-land) {
      height: 4.5rem;
      width: auto;
    }
  }
}
