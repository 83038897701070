@import '../../../sass/mixins';
@import '../../../sass/variables.module';

@keyframes borderRadius {
  from {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  to {
    border-radius: 10px;
  }
}

.header {
  position: fixed;
  top: 2rem;
  width: calc(100% - 10rem);
  margin-left: 5rem;
  z-index: 2;
  transition: transform .2s;
}

.header-container {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  padding: 0 1.5rem;
  box-shadow: 0px 4px 10px 4px rgba(0, 0, 0, .1);
  background-color: white;

  @include respond (tab-port) {
    align-items: center;
  }
  
  & a {
    text-decoration: none;
  }

  // closed
  &--round {
    // animation: borderRadiusRemove 2s;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  
  // open
  &--semiround {
    animation: .35s borderRadius;
    border-radius: 8px;
  }
}

.mobile-menu {
  background-color: white;
  box-shadow: 0px 7px 10px rgba(0, 0, 0, .1);
  position: relative;
  height: 0px;
  transition: height .3s ease;
  overflow: hidden;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;


  &--active {
    height: 33rem; 
  }
}

.mobile-nav-item {
  display: block;
  text-align: center;
  padding: 1rem 0;
  color: $color-primary;
  text-decoration: none;
  font-size: 2.3rem;
  font-weight: 600;
  text-transform: uppercase;
  cursor: pointer;
  transition: font-weight .2s;
  position: relative;

  &:hover,
  &--selected {
    font-weight: 800;
  }

  &--contact {
    margin-top: 1.5rem;
    padding: 2rem 0;
    
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      height: 1px;
      width: 80%;
      background-color: $color-primary;
    } 
  }
}