@import '../../../../sass/variables.module';
@import '../../../../sass/mixins';


.heading-primary {
  font-size: 4.5rem;
  margin-bottom: 2rem;
  line-height: 1.3;

  &--bold {
    font-weight: 900;
    display: block;
  }

  &--thin {
    display: block;
    font-weight: 300;
  }

  @include respond(tab-port) {
    font-size: 3.5rem;
  }
}
