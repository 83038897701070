@import '../../../../sass/variables.module';
@import '../../../../sass/mixins';

.heading-secondary {
  margin-bottom: 2rem;
  font-size: 3.7rem;
  display: flex;
  flex-wrap: wrap;

  &--bold {
    font-weight: 800;
  }

  &--thin {
    font-weight: 300;
  }

  &--highlighted {
    background-color: $color-secondary;
    border-radius: 5px;
    padding: 0 .5rem .2rem .5rem;
  }

  &--underlined {
    display: inline-block;

    &::after {
      content: '';
      height: .4rem;
      background: $color-secondary;
      border-radius: 5px;
      display: block;
      margin-top: .3rem;
    }
  }

  & > span:not(:last-child) {
    margin-right: .7rem;
  }

  @include respond(tab-port) {
    font-size: 3.2rem;
  }

  @include respond(phone) {
    font-size: 3.5rem;
    margin-bottom: 1rem;
  }
}
