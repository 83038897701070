@import '../../../sass/mixins';

.watIsCSTM {
    width: 100%;
    display: flex;

    & div {
      padding: 4rem;
    }

    &-img {
      background-image: url('../../../assets/img/3.png');
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      width: 100%;
  
      @include respond(tab-port) {
        display: none;
      }
    }
  
    &-text {  
      @include respond(phone) {
        text-align: center;
      }
    }
  
    &-btn {
      // grid-area: button;
    }
}