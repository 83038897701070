@import "../../../sass/variables.module";

.input {
    width: 100%;
    position: relative;
    background-color: rgba(255,255,255,0.15);
    color: $color-secondary-light;
    border: none;
    border-radius: 4px;
    outline: none;
    padding: 1rem;
    font-size: 1.5rem;
    font-family: inherit;
    transition: 0.3s opacity;

    &::placeholder {
      opacity: 0.5;
    }

    &.regular {
      height: 4.5rem;
      line-height: normal;
    }

    &.lg{
      resize: none;
    }

    &:hover {
      cursor: pointer;
      opacity: 0.6;
    }

    &:focus {
      background-color: rgba(255,255,255,0.4);
      opacity: 1;
      caret-color: $color-secondary-light;
    }

    &::placeholder {
      color: $color-secondary-light;
    }
}


