@import '../../../sass/variables.module';
@import '../../../sass/mixins';

.sidebar-container {
  position: fixed;
  right: 0;
  top: 0;
  z-index: 98;
}

.contact {
    &-container {
      background-color: $color-primary;
      height: 100vh;
      width: 45rem;
      margin-right: 0;
      position: fixed;
      top: 0;
      right: 0;
      z-index: 99;
      padding: 3rem 7rem;
      transition: transform 0.3s;

    // hide scrollbar for IE, Edge and Firefox
    -ms-overflow-style: none; // IE and Edge
    scrollbar-width: none; // Firefox

    // Hide scrollbar for Chrome, Safari and Opera
    &::-webkit-scrollbar {
      display: none;
    }

    &.hidden {
      transform: translateX(45rem);
    }

    @include respond(phone) {
      justify-content: flex-start;
      display: flex;
      flex-direction: column;
      padding: 3rem 5rem;
      width: 43rem;

      &-head {
        display: flex;
        align-items: center;

        > :first-child {
          order: 2;
        }
      }
    }
  }

  &-form > * {
    margin-bottom: 2rem;
  }

  &-send-privacy {
    flex-grow: 1;
    display: flex;
    align-items: flex-start;

    p {
      font-size: 12px;
      width: 50%;
    }

    button {
      &:hover {
        background-color: $color-secondary-dark !important;
      }
    }
  }

  &-image-corner {
    position: absolute;
    bottom: 0;
    right: 0;
    transform: translate(50%, 50%);
    height: 40rem;
    width: auto;
    clip-path: polygon(0 0, 50% 0, 50% 50%, 0 50%);
  }
}

.react-icon-regular {
  cursor: pointer;
  margin-left: auto;
  display: flex;
}

.mailus {
  color: white;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 900;
  font-size: 1.7rem;
  position: relative;
  padding-left: 1rem;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 3px;
    border-radius: 10px;
    height: 100%;
    background-color: $color-secondary;
  }
}
