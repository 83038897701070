@import '../../../sass/variables.module';
@import '../../../sass/mixins';

.whatWeValue {
  &-biggrid {
    @include big-grid-columns;
  }

  &-container {
    grid-column: center-start / center-end;
    display: grid;
    row-gap: 4rem;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: 
    "valueText card1"
    "card2 missionText";

    @include respond(tab-land) {
      padding: 0 6rem;
    }

    @include respond(phone) {
      grid-template-areas: 
      "valueText"
      "card1"
      "missionText"
      "card2";
      grid-template-columns: 1fr;
      justify-items: center;
    }
  }

  &-values, &-mission {
    display: flex;
    gap: 5rem;

    @include respond(phone) {
      justify-content: center;
    }

    svg {
      opacity: 0.8;
      transform: rotateX(180deg) rotateZ(-5deg) translateY(8px);

      @include respond(phone) {
        display: none;
      }
    }
  }

  &-values {
    &-outer {
      grid-area: valueText;
    }
  }

  &-mission {
    &-outer {
      grid-area: missionText;
      align-self: center; 
      
      @include respond(tab-port) {
        margin-left: 3rem;
      }
      
       @include respond(phone) {
        margin-left: 0;
      }
    }

    & > svg {
      transform: rotateY(180deg) translateY(-8px);
    }
  }

  &-heading {
    font-size: 4.5rem;
    font-weight: 800;
    color: $color-primary;
    line-height: 1.05em;
    
    @include respond(phone) {
      text-align: center;
    }
  }

  &-card {
    background-color: white;
    box-shadow: $box-shadow-primary;
    padding: 3rem;
    border-radius: 1rem;

    &.card--1 {
      grid-area: card1;
      max-width: 60rem;

      @include respond(tab-port) {
        margin-left: 3rem;
      }

       @include respond(phone) {
        margin-left: 0;
        max-width: unset;
        margin-bottom: 4rem;
      }
    }

    &.card--2 {
      grid-area: card2;
      max-width: 40rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      position: relative;

      & > svg {
        // width: 8rem;
        // height: auto;
        color: $color-secondary;
      }

      &::after {
        content: "";
        width: 50%;
        height: 3px;
        background-color: $color-secondary;
        border-radius: 100px;
      }

      @include respond(tab-port) {
        margin-right: 3rem;
      }

      @include respond(phone) {
        margin-right: 0;
        max-width: unset;
      }
    }

    & > h4 {
      font-size: 2.5rem;
      color: $color-primary;
      font-weight: 800;
    }
  }
}
