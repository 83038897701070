@import '../../../sass/variables.module';
@import '../../../sass/mixins';

$border-radius: 2.5rem;

.tools-card {
  &-container {
    align-self: start;
    background-color: $color-black-light;
    border-radius: $border-radius;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 3px 3px 10px 3px;
  }

  &-image {
    background-position: center;
    background-size: cover;
    height: 20rem;
    width: 100%;
    border-radius: $border-radius;
    overflow: hidden;
    display: block;
    margin: 0 auto;
  }

  &-title {
    text-transform: uppercase;
    font-weight: 800;
    letter-spacing: 1.1px;
    text-align: center;
  }

  &-text-container { 
    padding: 0 2em;
    transition: all .3s ease;
    height: 0;
    overflow: hidden;

    & > p {
      color: #d3d3d3;
      text-align: center;
    }
  }

  &-arrow {
    margin-top: auto;
    transition: transform .3s ease;

    &:hover {
      cursor: pointer;
    }

    &--rotate {
      transform: rotate(180deg);
    }
  }
  
  &-logo-row {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3rem;
    margin: 3rem 0;
  }
}