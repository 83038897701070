@import '../../../sass/variables.module';

.person {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: white;
  height: 30rem;
  width: 22.5rem;

  &-fig {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    height: 70%;
    width: 70%;
    object-fit: cover;
    border-radius: 0.5rem;
    margin-bottom: 1.5rem;

    &-img {
      min-height: 100%;
      min-width: 100%;
      object-fit: cover;
    }
  }

  &-name {
    font-weight: 800;
    font-size: 1.8rem;
  }

  &-function {
    background-color: $color-secondary-light;
    border-radius: 0.5rem;
    padding: 4px 7px;
    width: max-content;
  }
}
