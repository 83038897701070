@import "../../../sass/variables.module";

.paragraph {
  font-size: $default-font-size;
  margin-bottom: 2rem;
  line-height: 1.4em;

  &--bold {
    font-weight: 800;
  }

  &--uppercase {
    text-transform: uppercase;
  }

  &--border-left{
    border-left: 3px solid $color-secondary;
    border-radius: 1px;
    padding-left: .7rem;
  }
}