@import '../../sass/variables.module';
@import '../../sass/mixins';

.pagenotfound-container {
  display: grid;
  @include big-grid-columns;
}

.error404-container {
  grid-column: full-start / full-end;
  text-align: center;
  margin: 10rem;
}
.errorpage-footer {
  background-color: $color-primary;
  grid-column: full-start / full-end;

  @include respond(tab-land) {
    padding: 0 5rem;
  }

  @include respond(phone) {
    padding: 0 4rem;
  }
}
