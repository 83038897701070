// COLORS
$color-primary: #171A64;
$color-primary-light:#44467E;
$color-primary-dark: #181433;
$color-secondary: #DEC0F1;
$color-secondary-light: #FCE7FD;
$color-secondary-dark: #D3ABEC;

$color-black: #212121;
$color-black-light: #2e2e2e;
$color-white: #FFF;
$color-grey: #C6C6C6;
$color-grey-light: #fcfcfc;
$color-grey-dark: #9b9b9b;

// FONT
$default-font-size: 1.7rem;
$default-font-family: 'Mulish';

// BOX SHADOW 
$box-shadow-primary: 0 1.6rem 4rem #7090B133;

:export {
  colorPrimary: $color-primary;
  colorPrimaryLight: $color-primary-light;
  colorPrimaryDark: $color-primary-dark;
  colorSecondary: $color-secondary;
  colorSecondaryLight: $color-secondary-light;
  colorSecondaryDark: $color-secondary-dark;

  colorBlack: $color-black;
  colorWhite: $color-white;
  colorGrey: $color-grey;
  colorGreyLight: $color-grey-light;
  colorGreyDark: $color-grey-dark;

  boxShadowPrimary: $box-shadow-primary;

  defaultFontSize: $default-font-size;
}