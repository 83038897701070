@import '../../sass/variables.module';
@import '../../sass/mixins';

.homepage-container {
  @include big-grid-columns;
  @include grid-template-rows;

  @include respond(tab-port) {
    grid-template-rows: repeat(5, min-content);
    margin-top: 15rem;
  }
}

.homepage-landing {
  background-color: $color-white;
  grid-column: center-start / center-end;
  display: grid;
  align-items: center;

  @include respond(tab-land) {
    padding: 0 5rem;
  }
}

.what-is-cstm {
  box-shadow: $box-shadow-primary;
  background-color: $color-white;
  grid-column: col-start 2 / col-end -2;
  border-radius: 1rem;
  overflow: hidden;
  margin-bottom: 10rem;

  @include respond(tab-port) {
    grid-column: center-start / center-end;
    margin-left: 5rem;
    margin-right: 5rem;
  }
}

.statistics {
  grid-column: center-start / center-end;
  margin-top: 6rem;
  margin-bottom: 6rem;

  @include respond(tab-land) {
    margin: 0 5rem
  }
}

.onzeTools {
  background-color: $color-black;
  grid-column: full-start / full-end;
  padding: 10rem 0;

  @include respond(tab-land) {
    padding: 6rem 5rem;
  }
}

.our-work {
  grid-column: full-start / full-end;
}

.homepage-footer {
  background-color: $color-primary;
  grid-column: full-start / full-end;
  
  @include respond(tab-land) {
    padding: 0 5rem;
  }

  @include respond(phone) {
    padding: 0 4rem;
  }
}
