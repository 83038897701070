@import '../../../sass/variables.module';
@import '../../../sass/mixins';

.statistics-container {
  display: grid;
  grid-template-columns: 1fr 2fr;
  justify-content: center;
  gap: 5rem;
  padding: 5rem 0rem 10rem 0rem;

  @include respond(phone) {
    grid-template-columns: repeat(auto-fill, 20rem);
  }

  @include respond(tab-port) {
    grid-template-columns: 1fr;
    padding: 5rem 0rem 10rem 0rem;
    gap: 2rem;
  }

  &-left, &-right {
    display: flex;
    flex-direction: column;
    gap: 3rem;

    &-heading {
      flex-direction: column;

    }

    &-arrow {
      margin-left: 4rem;
    }

    & > svg {
      @include respond(tab-land) {
        width: 90px;
        height: auto;
      }

      @include respond(tab-port) {
        display: none;
      }
    }
  }

  &-cards {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 3rem;
    list-style: none;

    @include respond(phone) {
      grid-template-columns: 1fr;
    }
  }
}

.statisticCard {
  background-color: $color-white;
  border-radius: 2rem;
  box-shadow: $box-shadow-primary;
  display: flex;
  align-items: center;
  padding: 1.5rem;

  &-icon {
    background-color: rgba($color-secondary-light, .6);;
    border-radius: 50%;
    height: 80px;
    width: 80px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    @include respond(tab-land) {
      height: 64px;
      width: 64px;
    }

    @include respond(phone) {
      height: 54px;
      width: 54px;
    }
  }

  &-heading {
    padding: 1rem 0 0 2rem;

    &-title {
      font-weight: 700;
      font-size: 1.8rem;
    }
  
    &-text {
      font-size: 1.7rem;
      color: $color-grey-dark;
    }
  }
}