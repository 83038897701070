@import '../../../sass/variables.module';
@import '../../../sass/mixins';

.project-card-box {
  transform: translateX(5rem);
  width: 45rem;
  height: 45rem;
  border-style: solid;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: $box-shadow-primary;
  border: 2px solid #dec0f165;
  border-radius: 1rem;
  border-radius: 1rem;
}

.project-card-content {
  display: flex;
  flex-direction: column;
}

.project-card-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 5rem;
  margin-right: 5rem;

  &-client {
    display: flex;
    flex-direction: row;
  }

  &-text {
    text-align: left;
  }
}

.project-card-row-less-margin {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 5rem;
  transform: translateY(-1rem);
}

.project-card-button {
  margin-top: 3rem;
}

.project-card-client {
  &-left {
  }
  &-right {
    margin-left: 15rem;
  }
}

.project-card-line {
  height: 0.2rem;
  border: 0;
  border-top: 0.2rem solid $color-secondary;
  padding: 0;
  width: 100%;
  margin-bottom: 3rem;
}
