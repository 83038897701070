@import '../../../sass/variables.module';
@import '../../../sass/mixins';

.our-team {
  @include big-grid-columns;
  display: grid;

  &-content {
    grid-column: center-start / center-end;
    display: flex;
    gap: 10rem;
    flex-direction: column;
    margin: 10rem 0rem 5rem 0rem;
  
    @include respond(tab-land) {
      align-items: center;
      gap: 5rem;
      padding: 0rem 6rem;
    }
  }

  &-top {
    text-align: center;
  }

  &-bottom {
    display: flex;
    justify-content: space-between;
    flex-basis: 55rem;
    margin-top: -10rem;

    @include respond(tab-land) {
      flex-direction: column;
      margin-top: 0;

      & div:nth-child(even) {
        display: none !important;
      }

      & div:nth-child(odd) {
        margin: 1rem 0;
      }
    }

    svg {
      opacity: 0.2;
    }

    & div:nth-child(2) {
      display: flex;
      align-items: center;
      margin-bottom: 10rem;

      svg {
        transform: rotate(80deg);
      }
    }

    & div:nth-child(3) {
      display: flex;
      align-items: flex-end;
    }

    & div:nth-child(4) {
      display: flex;
      align-items: center;
      margin-top: 10rem;
    }

    & div:nth-child(5) {
      display: flex;
      align-items: flex-end;
      margin-bottom: 5rem;
    }

    & div:nth-child(6) {
      display: flex;
      align-items: center;
    }
  }
}
