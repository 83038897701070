@import '../../../sass/variables.module';
@import '../../../sass/mixins';

.uptodate-container {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-column: center-start / center-end;
  grid-auto-rows: max-content;
  background-color: #fce7fd;
  justify-items: center;
  align-content: center;
  height: 35vh;
  gap: 3rem;

  @include respond(tab-land) {
    height: 19vh;
  }

  @include respond(phone) {
    height: auto;
    padding: 5rem 0;
  }

  &-head {
    display: flex;
    align-items: center;
  }

  &-form {
    @include respond(phone) {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 4rem;
    }
    
    &-input {
      font-size: 1.7rem;
      display: inline-block;
      padding: 13px 15px;
      color: $color-primary;
      box-shadow: 0px 16px 40px #7090b133;
      border: 1px solid #b2b2b2a5;
      border-radius: .5rem;
      opacity: 1;
      margin-right: 1rem;
      font-family: $default-font-family, sans-serif;
      transition: 0.3s;
      box-shadow: $box-shadow-primary;

      &:hover {
        cursor: pointer;
      }

      &:focus {
        cursor:text;
        caret-color: $color-primary;
        outline: none !important;
        border: 1px solid $color-primary;
        box-shadow: 0 .5rem 2rem rgba(0, 0, 0, .2);
      }

      &::placeholder {
        color: $color-grey;
        opacity: 0.9;
        font-size: 1.7rem;
      }

      @include respond(phone) {
        width: 80%;
        min-width: 50px;
      }
    }
  }
}

// @media not all and (max-width: 768px) {
//   .text-container {
//     &-c {
//       background-color: red;
//       opacity: 0.5;
//       flex-grow: 1;
//     }
//     &-s {
//       background-color: blue;
//       opacity: 0.5;
//       flex-grow: 1;
//     }
//     &-t {
//       background-color: green;
//       opacity: 0.5;
//       flex-grow: 1;
//     }
//     &-m {
//       background-color: yellow;
//       opacity: 0.5;
//       flex-grow: 1;
//     }
//     &-middle {
//       flex-grow: 2;
//       text-align: center;
//     }
//   }
// }

// @media all and (max-width: 768px) {
//   .text-container {
//     &-c {
//       display: none;
//     }
//     &-s {
//       display: none;
//     }
//     &-t {
//       display: none;
//     }
//     &-m {
//       display: none;
//     }
//     &-middle {
//       flex-grow: 2;
//       text-align: center;
//     }
//   }
// }


