@import '../../../sass/mixins';

.onze-tools {

  &-container {
    @include big-grid-columns;
    display: grid;
  }
  
  &-content {
    grid-column: center-start / center-end;
  }

  &-heading-container {
    display: grid;
    grid-template-columns: 5fr 1fr;
  }
}

.onze-tools-octopus {
  width: 5rem;
  height: 6rem;
  transform: translateY(-50%);
  color: white;
  font-size: 1.5rem;
}

.cards-container {
  margin-top: 5rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 5rem;

  @include respond(tab-port) {
    grid-template-columns: 1fr;
    max-width: 500px;
    margin: 5rem auto 0 auto;
  }
}