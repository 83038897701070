@import './sass/variables.module';
@import './sass/mixins';

*,
*::after,
*::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* define font root size -> define what 1rem is
font size = normaal 16px. Wij willen dit veranderen naar 10px 
de gebruiker moet dit echter kunnen aanpassen en daarom maken we het relatief met percentages
normaal fontsize = 16px. 62.5% * 16px = 10px. Als de gebruiker nu de root font-size verandert naar
18px, dan wordt onze font-size ook groter doordat het met percentages werkt ipv harde pixels.
1 rem = root fontsize -> so now: 1rem = 10px instead of 1rem = 16px */
html {
  font-size: 62.5%; /* 1rem = 10px; 10px/16px = 62.5% */
  overflow-x: hidden;

  @include respond(big-desktop) {
    font-size: 70%;
  }

  @media screen and (max-width: 1425px) {
    font-size: 50%;
  }
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: $default-font-family, sans-serif;
  font-weight: 400;
  line-height: 1.7,
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
