@import '../../sass/variables.module';
@import '../../sass/mixins';

.onswerk-container {
  display: grid;
  grid-template-rows: 96vh repeat(5, min-content);
  @include big-grid-columns;
}

.onswerk-latest-work {
  background-color: $color-white;
  grid-column: full-start / full-end;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: white;
  margin-top: 10rem;
}

.onswerk-the-work {
  grid-column: full-start / full-end;
  padding: 10rem 0;
  background-color: black;
}

.onswerk-joined-forces {
  grid-column: full-start / full-end;
  padding: 10rem 0;
  background-color: blue;
}

.onswerk-footer {
  grid-column: full-start / full-end;
}
