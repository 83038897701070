@import '../../sass/variables.module';
@import '../../sass/mixins';

.termsAndConditions {
  width: 100%;
  max-width: 80rem;
  margin: 0 auto;
  padding: 5rem 0;

  @include respond(tab-port) {
    margin-top: 10rem;
    padding: 5rem 6rem;
  }

  & h1 {
    font-size: 4rem;
    color: $color-primary;
  }

  & h2 {
    font-size: 2.5rem;
    color: $color-primary;
  }

  & article {
    margin-top: 4rem;

    & ol {
      padding: 0 5rem;

      & li {
        font-size: $default-font-size;
        padding: 1rem 0;
        color: $color-primary;
      }
    }
  
  }

}