@import '../../../sass/variables.module';
@import '../../../sass/mixins';

.we-build-container {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-column: center-start / center-end;
  margin-bottom: 10rem;

  @include respond(tab-land) {
    padding: 6rem;
    margin-bottom: 0;
  }
  
  &-head {
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 1;

    h3 {
      color: $color-primary;
      padding-bottom: 5rem;
    }
  }

  &-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @include respond(tab-land) {
      svg {
        height: auto;
        width: 6rem;
      }
    }

    @include respond(phone) {
      flex-direction: column;
    }
  }
}
