@import '../../../sass/variables.module';
@import '../../../sass/mixins';

.latest-work-container {
  @include big-grid-columns;
  display: grid;
}

.latest-work-content {
  grid-column: center-start / center-end;
  display: flex;

  &-left {
    width: 45%;
    flex-grow: 3;
  }

  &-middle {
    width: 10%;
    flex-grow: 1;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    opacity: 0.3;
    margin-top: 10rem;
  }

  &-right {
    width: 45%;
    flex-grow: 3;
    text-align: center;
  }
}

.latest-work-text-container {
  display: flex;
  flex-direction: row;
  width: 80%;
}

.latest-work-line {
  height: 7rem;
  width: 1.3rem;
  background-color: #dec0f1;
  border-radius: 9.9rem;
  margin: 0 1rem;
}

.latest-work-dashboard-img {
  margin-top: 3rem;
  height: 50%;
}
