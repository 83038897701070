@import '../../../sass/variables.module';
@import '../../../sass/mixins';

.stepper {

  &-title {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;

    &-number {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $color-secondary;
      border-radius: 10rem;
      height: 3.8rem;
      width: 3.8rem;
      transform: translateX(-53%);

      &-p {
        font-size: 1.95rem;

        @include respond(tab-port) {
          font-size: 1.8rem;
        }
      }
    }
  }

  &-item {
    display: flex;
    align-items: center;
    margin-bottom: 3rem;

    &-number {
      display: flex;
      align-items: center;
      justify-content: center;

      transform: translateX(-57%);

      background-color: $color-secondary-light;
      border-radius: 10rem;
      height: 1.6rem;
      width: 1.6rem;
      margin-right: 2rem;

      @include respond(tab-port) {
        margin-right: 2.2rem;
      }

      &-p {
        @include respond(tab-port) {
          font-size: 1.6rem;
        }
      }
    }
  }
}