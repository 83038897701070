@import '../../../sass/variables.module';
@import '../../../sass/mixins';

.addedValue-container {
    display: flex;
    gap: 14rem;

    & div {
        width: 100%;
    }

    @include respond(tab-port) {
        gap: 6rem;
    }

    @include respond(phone) {
        gap: 0;
        flex-direction: column;
    }
}
