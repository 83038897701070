@import '../../../sass/variables.module';
@import '../../../sass/mixins';

.arrow-to-top {
  background-color: red;
  display: inline-block;
  position: fixed;
  height: 6rem;
  width: 6rem;

  & svg + svg {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.arrow-up-inner-container {
  transition: all .2s ease;
}

.arrow-up-container {
  &,
  &:link,
  &:visited { 
    z-index: 2;     
    border: none;
    cursor: pointer;  
    position: fixed;
    background-color: transparent;
    background-image: url('../../../assets/icons/arrowToTopBackground.svg');
    background-position: center;
    background-size: contain;
    height: 6rem;
    width: 6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    bottom: 5rem;
    right: 5rem;
    transition: all .2s ease;
  }

  &:hover {
    transform: translateY(-.2rem);
    box-shadow: 0 .5rem 3rem rgba(0, 0, 0, .3);
  }

  // &:focus 
  &:active {
    transform: translateY(.2rem);
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .3);
  }

  & svg {
    @include respond (tab-land) {
      width: 19px;
      height: auto;
    }
  }
}