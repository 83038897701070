@import '../../../../sass/variables.module';

.heading-tertiary {
  color: $color-secondary;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 1.8rem;
  padding-bottom: 1rem;
}

