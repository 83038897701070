@import '../../../sass/variables.module';

.Toastify {
    &__toast-body {
        font-size: 1.5rem !important;
        font-family: $default-font-family, sans-serif;
    }

    &__toast-icon {
        svg {
            fill: $color-secondary;
        }
    }
}